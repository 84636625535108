@import "../../App.scss";


.speech-component{

    .mic-on-control{
        z-index: 1;
    }
    .circle-1 {
        margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        position: absolute;
        z-index: 0;
        background-color: #acccf1;
        animation: wave 2s ease-in-out infinite;
      }
  
      @keyframes wave {
        0% {
          transform: scale(0.8);
          background-color: #acccf1;
        }
        50% {
          transform: scale(0.9);
          background-color: #2b81e3;
        }
        100% {
          transform: scale(0.8);
          background-color: #acccf1;
        }
      }

      .circle-2 {
        margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        position: absolute;
        z-index: 0;
        opacity: 0.5;
        background-color: #8dbff7;
        animation: wave2 2s linear infinite;
        animation-delay: 0s;
      }
  
      @keyframes wave2 {
        0% {
          transform: scale(0.8);
          background-color: #8dbff7;
        }
        50% {
          transform: scale(1);
          background-color: #73abec;
        }
        100% {
          transform: scale(0.8);
          background-color: #8dbff7;
        }
      }

      .vocal-lang-text{
        text-align: center;
        font-family: $fontMedium;
        font-size: 13px;
        color: $text-color;
      }
}