@import "../../App.scss";


.step-comp {

    display: flex;
    height: 100vh;
    width: 100%;



    .step-control {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 100%;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 100%;
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }


        .step-left-control {
            background-color: $blue-link;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

            .img-logo-jobhack {
                width: 70%;
                height: 51px;
                object-fit: contain;
                margin-top: 15px;
            }

            .img-left-control {
                width: 100%;
                height: auto;
                object-fit: contain;
                margin-left: auto;
                margin-top: 15%;
                margin-right: -15%;
                margin-bottom: auto;
            }

            .img-left-control-result {
                width: 100%;
                height: auto;
                object-fit: contain;
                margin-left: auto;
                margin-top: auto;
                margin-right: -15%;
                margin-bottom: auto;
                @include media-breakpoint-down(xl){
                    margin-top: 45%;
                    width: 120%;
                }
                @include media-breakpoint-down(lg){
                    margin-top: 45%;
                    width: 120%;
                }
            }
        }

        .step-mic-control{
            margin-left: auto;
            margin-right: auto;
            margin-top: -15%;
            display: flex;
            order: 2;
            @include media-breakpoint-down(lg){
                margin-top: -10%;
            }
            @include media-breakpoint-down(md){
                order: 1;
                margin-top: 16px;
            }

            .step-mic-img{
                cursor: pointer;
                margin-left: auto;
                margin-right: auto;
                width: 120px;
                height: 120px;
                object-fit: contain;
            }
        }

        .step-right-control {
            display: flex;
            flex-direction: column;
            padding-top: 24px;
            width: 100%;
            height: 100%;
         

            @include media-breakpoint-down(md){
                padding:  12px;
                width: 100%;
                padding-bottom: 60px !important;
            }

            .breadcrumbs-control {
                cursor: pointer;
                font-size: 14px;
                font-family: $fontRegular;
                color: $button-primary-color;
                display: flex;

                .breadcrumbs-img {
                    width: 25px;
                    height: 25px;
                    object-fit: contain;
                    margin-right: 16px;

                    @include media-breakpoint-down(md){
                        margin-right: 6px;
                    }
                }

                .breadcrumbs-text {
                    font-family: $fontMedium;
                    text-decoration: underline;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }

            .img-left-control {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    width: 40%;
                    height: auto;
                    margin-top: 16px;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                }
                @include media-breakpoint-down(sm) {
                    display: block;
                    width: 50%;
                    height: auto;
                    margin-top: 16px;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .img-left-control-result {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    width: 40%;
                    height: auto;
                    margin-top: 16px;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                }
                @include media-breakpoint-down(sm) {
                    display: block;
                    width: 50%;
                    height: auto;
                    margin-top: 16px;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .step-hr-ask-control{
                display: flex;
                margin-left: auto;
                margin-right: auto;
                margin-top: 40px;

                @include media-breakpoint-down(md){
                    flex-direction: column;
                }


                .step-hr-ask-img{
                    width: 350px;
                    height: auto;
                    object-fit: contain;
                    margin-top: 10%;
                    margin-bottom: 50px;
                    order: 0;
                  
                    @include media-breakpoint-down(lg){
                        order: 1;
                        width: 250px;
                        margin-top: 10%;
                    }
                    @include media-breakpoint-down(md){
                        order: 1;
                        width: 300px;
                        margin-top: -7%;
                        margin-left: auto;
                        z-index: 1;
                    }
                }
                .step-hr-result-img{
                    width: 400px;
                    height: auto;
                    object-fit: contain;
                    margin-top: auto;
                    order: 0;
                    @include media-breakpoint-down(lg){
                        order: 1;
                        width: 250px;
                        margin-top: 10%;
                    }
                    @include media-breakpoint-down(md){
                        order: 1;
                        width: 300px;
                        margin-top: -5%;
                        margin-left: auto;
                    }
                }
                
                .step-hr-bubble-control{
                    width: 500px;
                    min-height: 150px;
                    margin-left: -40px;
                    order: 1;
                    @include media-breakpoint-down(lg){
                      
                        width: 100%;
                        min-height: 100px;
                    }
                    @include media-breakpoint-down(md){
                        order: 0;
                        margin-left: auto;
                        margin-right: auto;
                        width: 100%;
                        min-height: 100px;
                    }
                    blockquote {
                        display: flex;
                        min-height: 100px;
                        position: relative;
                        flex-direction: column;
                        background-color: #fff;
                        color: #333;
                        padding: 20px;
                        border-radius: 32px;
                        box-shadow: 0px 8px 22px rgba(24, 39, 75, 0.12), 0px 14px 64px rgba(24, 39, 75, 0.12);

                        @include media-breakpoint-down(md){
                            min-height: 150px;
                        }
                      }
                      
                      blockquote:before {
                        content: "";
                        position: absolute;
                        bottom: -32px;
                        left: 20px;
                        border-width: 20px;
                        rotate: 200deg;
                        border-style: solid;
                        border-color: transparent transparent #fff transparent;
                        @include media-breakpoint-down(md){
                            right: 50px;
                            left: auto;
                        }
                      }

                      .blockquote-text{
                        font-size: 17px;
                        font-family: $fontMedium;
                        color: $text-color;
                      }

                      .speak-img{
                        cursor: pointer;
                        width: 50px;
                        height: 50px;
                        object-fit: contain;
                        right: 0;
                        bottom: 0;
                        margin-right: 5%;
                        margin-bottom: 10px;
                        margin-left: auto;
                      }
                }
            }

            .step-questions-control {
                display: flex;
                flex-direction: column;
                margin-top: 24px;
                width: 70%;
                margin-left: auto;
                margin-right: auto;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                .step-questions-header {
                    font-size: 26px;
                    font-family: $fontBold;
                    color: $button-primary-color;
                    text-align: center;

                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                    }
                }
                .step-questions-textarea-control{
                    background-color: white;
                    width: 100%;
                    min-height: 600px;
                    padding-left: 24px;
                    padding-right: 24px;
                    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
                    border-radius: 32px 32px 0px 0px;
                    display: flex;
                    flex-direction: column;

                    @include media-breakpoint-down(md){
                        min-height: 500px;
                        padding-left: 14px;
                        padding-right: 14px;
                    }


                }
               
                .step-questions-textarea {
                    margin-top: 24px;
                    min-height: 100px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    color: #333;
                    padding: 20px;
                    border:none;
                    border-radius: 32px;
                    box-shadow: 0px 8px 22px rgba(24, 39, 75, 0.12), 0px 14px 64px rgba(24, 39, 75, 0.12);
                    order: 0;

                    @include media-breakpoint-down(md){
                        min-height: 50px;
                       
                    }

                    .form-control{
                        font-size: 15px !important;
                        font-family: $fontRegular !important;
                        border: none !important;

                        @include media-breakpoint-down(md){
                            border: none !important;

                            font-size: 12px !important;
                        }
                    }
                  }
                  
                  .step-questions-textarea:before {
                    content: "";
                    position: absolute;
                    bottom: -32px;
                    left: 20px;
                    border-width: 20px;
                    rotate: 200deg;
                    border-style: solid;
                    border-color: transparent transparent #fff transparent;
                  }

                .step-questions-button-control {
                    display: flex;
                    margin-top: 24px;
                    margin-left: auto;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }

                .step-questions-button-control-result{
                    display: flex;
                    flex-direction: column;
                    margin-top: 24px;
                    margin-left: auto;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }

                .step-questions-button {
                    width: 200px;
                    height: 41px;
                    border-radius: 12px;
                    color: white;
                    margin-left: 14px;
                    font-family: $fontMedium;
                    font-size: 14px;
                    border: none;
                    background-color: $button-primary-color;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin-left: 7px;
                    }
                }

                .step-questions-candidate-img{
                    margin-left: auto;
                    width: 200px;
                    height: auto;
                    object-fit: contain;
                    margin-right: -10%;
                    margin-top: 16px;
                    order: 1;

                    @include media-breakpoint-down(md){
                        order: 2;
                        margin-right: auto;
                        margin-left: auto;
                    }
                }

                .step-questions-back {
                    width: 200px;
                    height: 41px;
                    border-radius: 12px;
                    color: white;
                    margin-left: 14px;
                    font-family: $fontMedium;
                    font-size: 14px;
                    border: none;
                    background-color: $soft-gray;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin-left: 0px;
                        margin-right: 7px;
                    }
                }
            }

            .step-questions-number-control {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 16px;
                margin-bottom: 0px;

                .step-progress-bar-control {
                    margin-left: auto;
                    width: 40%;
                    margin-right: auto;
                    display: flex;
                    flex-direction: column;
                    height: auto;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }

                    .step-progress-text {
                        text-align: center;
                        font-size: 18px;
                        color: $text-gray;
                        font-family: $fontMedium;
                    }
                }

                .step-terms-control {
                    display: flex;
                    margin-top: 24px;

                    .step-terms-link {
                        font-size: 15px;
                        color: $button-primary-color;
                        font-family: $fontMedium;
                        margin-right: 24px;
                        background-color: transparent !important;
                        border: none !important;

                        &:hover {
                            text-decoration: underline;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 12px;
                        }
                    }


                }

                .step-copyright-text {
                    margin-top: 16px;
                    font-size: 14px;
                    color: #808080;
                    font-family: $fontRegular;

                    @include media-breakpoint-down(md) {
                        font-size: 10px;
                    }
                }
            }
        }

        .step-footer-control{
            order: 4;
            margin-top: auto;
            margin-bottom: 16px;
        }
    }

    .progress-bar {
        color: $text-color !important;
        background-color: $dark-blue !important;
    }
}