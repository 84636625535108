@import "../../App.scss";

.result-comp{

    width: 70%;
    display: flex;
    height: 100%;
    margin: auto;

    @include media-breakpoint-down(md){
        width: 100%;
    }


    .result-control{
      
        margin: auto;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md){
            width: 100%;
        }
        .result-text-header-left{
            font-size: 26px;
            font-family: $fontMedium;
            color: $text-color;
            text-align: left;

            @include media-breakpoint-down(md){
                font-size: 18px;
                text-align: center;
            }
        }
        .result-text-header{
            margin-top: 24px;
            font-size: 26px;
            font-family: $fontMedium;
            color: $text-color;
            text-align: center;
            @include media-breakpoint-down(lg){
                font-size: 20px;
            }
            @include media-breakpoint-down(md){
                font-size: 18px;
            }
        }

        .result-button-test-again{
            margin-top: 36px;
            width: 250px;
            height: 45px;
            border-radius: 12px;
            border: none !important;
            color: white;
            font-family: $fontMedium;
            font-size: 17px;
            background-color: $button-primary-color;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(md){
                width: 90%;
            }
        }

        .tips-control{
            display: flex;
            flex-direction: column;
            margin-top: 60px;

            .tips-header{
                font-size: 16px;
                color: $text-gray;
                font-family: $fontMedium;
            }

            .tips-desc{
                font-size: 11px;
                font-family: $fontRegular;
                color: $text-gray;

                .tips-desc-li{
                    margin-left: 6px;
                    font-size: 11px;
                    font-family: $fontRegular;
                    color: $text-gray;
                }
            }
        }
    }

    .result-control-width{
        width: 100% !important;
    }
    .result-hr-ask-control{
        display: flex;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        margin-top: 40px;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }


        .result-hr-result-img{
            width: 100%;
            height: 250px;
            object-fit: contain;
            margin-top: auto;
            order: 0;
           
        }

        .result-answer-control{
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            .result-answer-header{
                cursor: pointer;
                color: $blue-link;
                margin-right: 15px;
                text-decoration: underline;
                font-size: 16px;
                margin-left: auto;
                font-family: $fontMedium;
            }
        }
        
        .result-hr-bubble-control{
            width: 80%;
            min-height: 150px;
            margin-left: auto;
            margin-right: auto;
            order: 1;
            @include media-breakpoint-down(lg){
              
                width: 100%;
                min-height: 100px;
            }
            @include media-breakpoint-down(md){
                order: 0;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                min-height: 100px;
            }
            blockquote {
                margin-top: 26px;
                display: flex;
                min-height: 100px;
                position: relative;
                flex-direction: column;
                background-color: #fff;
                color: #333;
                padding: 20px;
                border-radius: 32px;
                box-shadow: 0px 8px 22px rgba(24, 39, 75, 0.12), 0px 14px 64px rgba(24, 39, 75, 0.12);

                @include media-breakpoint-down(md){
                    min-height: 150px;
                }
              }
              
              blockquote:before {
                content: "";
                position: absolute;
                top: -10px;
                left: 40%;
                
                border-width: 20px;
                rotate: 270deg;
                border-style: solid;
                border-color: transparent transparent #fff transparent;
               
              }
              .blockquote-header{
                font-size: 17px;
                font-family: $fontMedium;
                color: $text-color;
                line-height: 1.7em;

                @include media-breakpoint-down(md){
                    font-size: 13px;
                }
              }
              .blockquote-text{
                font-size: 17px;
                font-family: $fontRegular;
                color: $text-color;
                line-height: 1.7em;

                @include media-breakpoint-down(md){
                    font-size: 13px;
                }
              }
              .result-answer-text-control{
                display: flex;
                flex-direction: column;
                padding-top: 6px;
                padding-bottom: 6px;
              }

              .result-answer-text-divider{
                width: 100%;
                height: 1px;
                background-color: #888;
                margin-top: 6px;
                margin-bottom: 6px;
              }

              .result-answer-text-bold{
                font-size: 14px;
                font-family: $fontMedium;
                color: $text-color;

                .result-answer-text{
                    font-size: 14px;
                    font-family: $fontRegular;
                    color: $text-color;
                  }
              }

              .speak-img{
                cursor: pointer;
                width: 50px;
                height: 50px;
                object-fit: contain;
                right: 0;
                bottom: 0;
                margin-right: 5%;
                margin-bottom: 10px;
                margin-left: auto;
              }

              .result-feedback-text{
                margin-top: 24px;
                text-decoration: underline;
                font-size: 16px;
                color: $text-color;
                font-family: $fontMedium;
                margin-left: auto;
                margin-right: auto;
                @include media-breakpoint-down(md){
                    font-size: 13px;
                }
                cursor: pointer;
              }
        }
    }

    .result-feedback-control{
        margin-top: 20%;

        .result-question-feedback-text{
            font-size: 18px;
            color: $text-color;
            font-family: $fontMedium;
            margin-bottom: 16px;
            @include media-breakpoint-down(md){
                font-size: 13px;
            }
        }

        .result-mic-control{
                margin-left: auto;
                margin-right: auto;
                display: flex;
                order: 2;
                @include media-breakpoint-down(lg){
                    margin-top: -10%;
                }
                @include media-breakpoint-down(md){
                    order: 1;
                    margin-top: 16px;
                }
    
                .step-mic-img{
                    cursor: pointer;
                    margin-left: auto;
                    margin-right: auto;
                    width: 120px;
                    height: 120px;
                    object-fit: contain;
                }
        }
    }
    .result-test-again{
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        order: 3;

        &:hover{
            text-decoration: underline;
        }

        .result-test-again-text{
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
            font-size: 26px;
            color: $text-color;
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;
            @include media-breakpoint-down(md){
                font-size: 13px;
            }
        }

        .result-test-again-arrow{
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-left: 16px;
            margin-top: auto;
            margin-bottom: auto;
        }


    }
    
    .result-img-left-control-result {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
            width: 40%;
            height: auto;
            margin-top: 16px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }
        @include media-breakpoint-down(sm) {
            display: block;
            width: 70%;
            height: auto;
            margin-top: 16px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }
    }
}