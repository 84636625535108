@import "../../App.scss";

.header-comp{

    background-color: transparent;
    width: 100%;
    height: 80px;
    display: flex;
  

    .header-control{
        height: 100%;
        display: flex;
        padding-left: 5%;
        padding-right: 5%;
    }

    .header-show-mobile{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .header-menu-control{
        margin-left: auto;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 14px;
        color: $text-color;
        font-family: $fontMedium;

        @include media-breakpoint-down(md){
            flex-direction: column;
            width: 100%;
            height: 100%;
            font-size: 18px;
        }

        .header-menu-nav-control{
            display: flex;
            flex-direction: column;
            margin-bottom: auto;
            padding-top: 14px;
        }


        .header-menu-link{
            cursor: pointer;
            margin-left: 12px;
            margin-right: 12px;
  
            &:hover{
          
                text-decoration: underline;                
            }

            @include media-breakpoint-down(md){
                width: 100%;
                text-align: left;
                margin-top: 12px;
                margin-bottom: 12px;
               
            }
        }

      
    }
    .header-menu-nav{
        cursor: pointer;
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
    .header-logo-img{
        cursor: pointer;
        width: 200px;
        height: 41px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-down(md){
            width: 160px;
            height: 41px;
        }
    }

    .header-open-nav{
        background-color: $primary-color;
        width: 100%;
        height: 100%;
        margin-top: 81px;
        z-index: 2;
        position: absolute;
        display: flex;

    }
}