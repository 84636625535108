@import "../../App.scss";

.loading-comp{

    width: 100%;
    height: 100vh;
    display: flex;

    .loading-control{
        margin: auto;
        display: flex;
        flex-direction: column;
        .loading-img{
            width: 300px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .loading-img-door-control{
            display: flex;
            position: relative;

            .loading-img-door-text{
                animation: fadeIn 4s;
                font-family: $fontMedium;
                color: white;
                font-size: 36px;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right:0 ;
                z-index: 2;
                text-align: center;
                margin-top: 35%;
                @include media-breakpoint-down(md){
                    margin-top: 35%;
                    font-size: 16px;
                }
            }
            .loading-img-door{
                width: 600px;
                height: auto;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;

                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }
        }
        
        .loading-text{
            font-size: 16px;
            color: $text-color;
            font-family: $fontMedium;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
}