@import "../../App.scss";

.landing-comp{
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 36px;

    .landing-cover-section{
        width: 100%;
        display: flex;
        margin-top: 24px;
        margin-bottom: 48px;
    }


        .landing-grid-control {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: grid;
            grid-template-columns: 50% 50%;

            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
            }
        }

        .landing-grid-left-control {
            display: flex;
            position: relative;
            padding-left: 66px;
            @include media-breakpoint-down(xl) {
                padding-left: 16px;
            }
            @include media-breakpoint-down(md) {
                padding-left: 0px;
                margin-top: 24px;
                padding-right: 0px;
            }

            .grid-50-control {
                display: grid;
                grid-template-columns: 50% 50%;
                @include media-breakpoint-down(xl) {
                    grid-template-columns: 100%;
                }
                @include media-breakpoint-down(md) {
                    grid-template-columns: 100%;
                }

                .item-control {
                    padding-right: 16px;

                    @include media-breakpoint-down(xl) {
                        padding-right: 0px;
                    }
                }
            }

            .with-control {
                width: 90%;
            }

            .form-bg-border {
                position: absolute;
                width: 80%;
                height: 100%;
                z-index: -1;
                margin-top: 30px;
                left: 30px;
                z-index: 0;
                background-color: $primary-color;
                border-radius: 32px;
            }
            .border-loading-control {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
                position: relative;
                text-align: center;
                background-color: white;

                .loading-json-control {
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    flex-direction: column;
                }
                .loading-json {
                    width: 160px;
                    height: 160px;
                }

                .loading-text {
                    text-align: center;
                    font-size: 24px;
                    color: $text-blue-gray;
                    font-family: $fontBold;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 24px;
                }
            }
            .form-border-control {
                z-index: 1;
                display: flex;
                border-radius: 32px;
                background-color: white;
                padding: 32px;
                height: 100%;
                width: 90%;
                margin-right: auto;
                @include media-breakpoint-down(xl) {
                    width: 100%;
                    padding: 32px 16px;
                }
                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                .text-form-header {
                    font-size: 24px;
                    color: $text-color;
                    font-family: $fontBold;

                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                    }
                }

                .text-title {
                    margin-top: 16px;
                    font-size: 13px;
                    font-family: $fontMedium;
                    color: $text-gray;
                    @include media-breakpoint-down(xxl) {
                        font-size: 14px;
                    }
                    @include media-breakpoint-down(lg) {
                        font-size: 14px;
                    }
                }
                .dropdown-item {
                    font-family: $fontMedium !important;
                }
                .input-control {
                    width: 100%;
                    height: 41px;
                    border-radius: 4px;
                    padding: 4px 16px;
                    border-color: $border-color;
                    font-family: $fontMedium !important;
                }

                .input-control-checkbox {
                    margin-left: 8px;
                }

                .text-error{
                    font-size: 12px;
                    font-family: $fontRegular;
                    color: red;
                }

                .text-consent {
                    font-size: 14px;
                    font-family: $fontRegular;
                    margin-top: 16px;
                    color: $text-color;

                    .blue {
                        color: $blue-link;
                    }
                }

                .upload-text-title{
                    font-size: 11px;
                    color: $text-gray;
                    font-family: $fontMedium;
                    
                }
                .upload-text{
                    cursor: pointer;
                    font-size: 11px;
                    color: $button-primary-color;
                    font-family: $fontMedium;
                    padding: 12px;
                    border-radius: 12px;
                    border: 2px $button-primary-color solid;
                }
                .button-upload {
                    width: 150px;
                    height: 41px;
                    margin-top: 4px;
                    margin-bottom: 12px;
                    border: 2px $button-primary-color solid;
                    background-color: white;
                    color: $button-primary-color;
                    font-family: $fontMedium;
                    font-size: 12px;
                    border-radius: 8px;
                }

                .button-border-red{
                    border: 2px red solid;
                }

                .button-submit {
                    padding: 13px;
                    width: 100%;
                    height: auto;
                    margin-top: 16px;
                    background-color: $button-primary-color;
                    color: white;
                    font-family: $fontMedium;
                    font-size: 16px;
                    border-radius: 8px;
                    border: none;
                }

                .text-desc-gray {
                    margin-top: 24px;
                    font-size: 13px;
                    font-family: $fontRegular;
                    color: $text-gray;
                }
            }
        }

        .landing-grid-right-control {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            width: 100%;
            padding-right: 24px;

            @include media-breakpoint-down(md) {
                padding-right: 0px;
            }

            .img-headline {
                width: 100%;
                height: auto;
                object-fit: contain;
                margin-left: auto;
                right: -30px;
                border-radius: 55px;

                @include media-breakpoint-down(md) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .text-control {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                text-align: left;
                flex-direction: column;
            }

            .text-headline {
                margin-top: 24px;
                font-size: 36px;
                font-family: $fontBold;
                color: $text-color;
                text-align: left;

                @include media-breakpoint-down(md) {
                    text-align: center;
                    font-size: 36px;
                }

                @include media-breakpoint-down(sm) {
                    text-align: center;
                    font-size: 24px;
                }
            }

            .text-subheadline {
                font-size: 24px;
                font-family: $fontMedium;
                color: $text-color;
                width: 100%;

                @include media-breakpoint-down(md) {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 24px;
                }

                @include media-breakpoint-down(sm) {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 18px;
                }
            }

            .text-descheadling {
                margin-top: 24px;
                font-size: 18px;
                font-family: $fontRegular;
                color: $text-color;
                width: 100%;

                @include media-breakpoint-down(md) {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 17px;
                }
                @include media-breakpoint-down(sm) {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 14px;
                }
            }
        }

        .resume-builder-text {
            cursor: pointer;
            margin-top: 16px;
            font-size: 14px;
            font-family: $fontMedium;
            color: $text-color;

            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                font-size: 14px;
            }
        }

        .resume-builder-text-special {
            background: linear-gradient(85deg, #AF34F4 0%, #7321E4 39.97%, #4008DC 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            position: relative;
            padding: 4px;
            font-weight: bold;

            &::after {
                content: '';
                position: absolute;
                bottom: 10%;
                left: 0;
                right: 0;
                height: 4px;
                background: linear-gradient(85deg, #AF34F4 0%, #7321E4 39.97%, #4008DC 100%);
                transform: rotate(-1.572deg);
                z-index: 0;
            }
        }
}