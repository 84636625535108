@import "../App.scss";


.error-modal {

    display: flex;
    flex-direction: column;

    .error-control{
        margin: auto;
        display: flex;
        flex-direction: column;

        .error-link{
            color: $primary-color;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
        .error-img{
            width: 200px;
            height: 200px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .error-text{
            font-size: 14px;
            color: $text-color;
            font-family: $fontRegular;
            text-align: center;
        }

        .error-close{
            margin-top: 26px;
            text-decoration: underline;
            font-size: 16px;
            cursor: pointer;
            color: $text-color;
            font-family: $fontRegular;
            text-align: center;
            display: flex;
            margin-left: auto;
            margin-right: auto;
        }

        .error-header{
            font-size: 16px;
            color: $text-color;
            font-family: $fontBold;
            text-align: center;
        }

        .error-button{
            min-width: 150px;
            height: 45px;
            background-color: $primary-color;
            color: white;
            font-family: $fontMedium;
            font-size: 18px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
        }
    }
}