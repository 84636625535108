@import "../../App.scss";



.loading-result-comp{
    width: 100%;
    height: 100vh;
    display: flex;

  
    .loading-result-control{
        margin: auto;
        display: flex;
        flex-direction: column;
        .loading-result-img{
            width: 300px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .loading-result-text{
            font-size: 16px;
            color: $text-color;
            font-family: $fontMedium;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }
}