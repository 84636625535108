@import "../node_modules/bootstrap/scss/bootstrap.scss";
$fontBold: "LINESeedSansTH_He";
$fontRegular: "LINESeedSansTH_Rg";
$fontMedium: "LINESeedSansTH_Bd";
$fontLight: "LINESeedSansTH_Th";

$soft-primary-color: #E3F0FE;
$primary-color: #CAE2FC;
$button-primary-color: #0E1D42;
$blue-link: #1A79E4;
$text-color: #0E1D42;
$text-blue-gray: #46597D;
$text-gray: #4C4C4C;
$border-color: #DDDDDD;
$soft-gray: #9b9b9b;
$dark-blue: #0E1D42;

body{
    background-color: $soft-primary-color;
    
}


::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@font-face {
    font-family: $fontBold;
    src: local("LINESeedSansTH_He"), url(../public/fonts/LINESeedSansTH_He.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("LINESeedSansTH_Bd"), url(../public/fonts/LINESeedSansTH_Bd.ttf) format("truetype");
}
@font-face {
    font-family: $fontRegular;
    src: local("LINESeedSansTH_Rg"), url(../public/fonts/LINESeedSansTH_Rg.ttf) format("truetype");
}
@font-face {
    font-family: $fontLight;
    src: local("LINESeedSansTH_Th"), url(../public/fonts/LINESeedSansTH_Th.ttf) format("truetype");
}
