@import "../../App.scss";

.footer-comp{
    width: 100%;
    display: flex;
    margin-top: auto;

    @include media-breakpoint-down(md){
        margin-top: 66px;
        
    }

    .footer-control{
        width: 100%;
        margin-top: auto;
        margin-left: auto;
        display: flex;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }
       

        .footer-link-control{
            display: flex;
            z-index: 2;
            margin-left: 5%;
        }

        .footer-link{

            font-size: 14px;
            color: $text-color;
            font-family: $fontMedium;
            cursor: pointer;
            margin-left: 14px;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }
        }

        .footer-copy-right{
            margin-right: 5%;
            margin-left: auto;
            font-size: 12px;
            color: $text-color;
            margin-top: 6px;
            font-family: $fontRegular;
        }
    }
}